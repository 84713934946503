/* eslint-disable */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as url from "helpers/url_helper";

import SentModal from 'common/Modals/SentModal';

/*import url and gateway methods */
import { getData, postData, uploadMultipleFile } from "utils/Gateway";

import brochurePdf from "assets/CourseDetailsAssests/images/PDF.jpg";
import brochureDoc from "assets/CourseDetailsAssests/images/DOC.jpg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";
import brochureXls from "assets/CourseDetailsAssests/images/XLS.jpg";

import { useTranslation } from "react-i18next";

const AskQuestion = () => {

    // user information
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const { t } = useTranslation();

    //console.log('userInfo', userInfo);


    // component states
    const [faqsubject, setFaqSubject] = useState('');
    const [faqdescription, setFaqDescription] = useState('');

    const [isUploading, setisUploading] = useState(false);
    const [uploadedFileCount, setUploadedFileCount] = useState(0);
    const [messageWarning, setmessageWarning] = useState(false);
    const [fileIsBig, setFileIsBig] = useState(false);

    const [uploadedFiles, setuploadedFiles] = useState([]);
    const [uploadedFileIds, setuploadedFileIds] = useState([]);

    // validation states for each field
    const [faqSubjectError, setFaqSubjectError ] = useState(false);
    const [faqDescriptionError, setFaqDescriptionError ] = useState(false);
    const [isValidate, setIsValidate] = useState(true);


    // function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        setIsValidate(true);
        
        // validate form fields
        // if (faqsubject === '') {
        //     setFaqSubjectError(true);

        //     setIsValidate(false);
        // } else {
        //     setFaqSubjectError(false);
        // }

        if (faqdescription === '') {
            setFaqDescriptionError(true);

            setIsValidate(false);
        } else {
            setFaqDescriptionError(false);
        }

        if(isValidate) {
            saveQuestion();
        }
    };

    //function to save question using API
    const saveQuestion = async () => {

        console.log('question is validate', isValidate);

        // check if validate is true
        if (isValidate) {
            try {
                const requestUrl = url.API_BaseUrl + url.API_CREATE_FAQ + `?token=${token}`;

                const data = {
                    //question: faqsubject,
                    question: faqdescription,
                    fileIds: uploadedFileIds.join(','),
                };

                console.log('data', data);

                console.log('requestUrl', requestUrl);

                const response = await postData(requestUrl, data);

                console.log(response);

                if (response.status) {

                    // close question modal
                    let questionModel = bootstrap.Modal.getInstance(document.getElementById("askQuestion"));
                    if (questionModel) {
                        questionModel.hide();
                    }

                    // show success message
                    let thanksModal = new bootstrap.Modal(
                    document.getElementById("sent_mdl")
                    );
                    thanksModal.show();
                
                                
                    setFaqSubject('');
                    setFaqDescription('');
                    setuploadedFiles([]);
                    setuploadedFileIds([]);
                    setmessageWarning(true);
                }
            } catch (error) {
                console.log(error.message);
            }
        
        }
    }


     //function upload multiple files
    const fileUploadHandler = async (e) => {
        try {
        setFileIsBig(false);

        setisUploading(true);

        const files = e.target.files;

        setUploadedFileCount(files.length);

        console.log('files', files);
        
        let fileProceed = true;
        // let fileProceed = false;

        // if (files.length > 0) {
        //     for (let i = 0; i < files.length; i++) {
        //     let f = files[i];

        //     if (f.size <= 12500000) {
        //         fileProceed = true;
        //     } else {
        //         fileProceed = false;
        //     }
        //     }
        // }

        if (fileProceed) {
            const requestUrl =
            url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

            const response = await uploadMultipleFile(requestUrl, files);

            setisUploading(false);

            console.log(requestUrl);
            console.log(response);

            if (response.status) {
                /*----- assign uploaded files with path for display start ------*/
                const uploadedFileValues = response.data.map(
                    ({ name, url, id, ...other }) => ({
                    name,
                    path: url,
                    filepath: url,
                    id,
                    })
                );

                //check old file exist or not if exist then new file will concat with old file
                if (uploadedFiles.length == 0) {
                    setuploadedFiles(uploadedFileValues);
                } else {
                    let fileArr = uploadedFiles.concat(uploadedFileValues);
                    setuploadedFiles(fileArr);
                }
                /*----- assign uploaded files with path for display end ------*/

                /*----- assign uploaded file ids start ------*/
                const fileIds = response.data.map(({ id }) => id);

                //check old file ids are exist or not.If exist then new id will concat with old id
                if (uploadedFileIds.length == 0) {
                    setuploadedFileIds(fileIds);
                } else {
                    let fileArr = uploadedFileIds.concat(fileIds);
                    setuploadedFileIds(fileArr);
                }
                /*----- assign uploaded file ids end ------*/

                resetFile();

            }
        } else {
            setFileIsBig(true);
        }
        } catch (error) {
        console.log(error.message);
        }
    };

    // function for clear file value
    const resetFile = () => {
        setUploadedFileCount(0);
        const file = document.getElementById("faqUploadFile");
        if (file) {
        file.value = null;
        } else {
        return;
        }
    };

    //function for delete file from upload stack
    const closeFileHandler = (index) => {
        const closedFile = [...uploadedFiles];
        const removedFileId = [...uploadedFileIds];

        closedFile.splice(index, 1);
        removedFileId.splice(index, 1);

        setuploadedFiles(closedFile);
        setuploadedFileIds(removedFileId);

        resetFile();
    };

    return (
        <>
        <div className="modal fade" 
            id="askQuestion" 
            tabIndex="-1" 
            aria-labelledby="askQuestionLabel" 
            aria-hidden="true" 
            data-bs-backdrop="static"
            >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header p-4 pb-0">
                        <h1 className="modal-title fs-5" id="askQuestionLabel">{t('Ask Question')}</h1>
                        <button type="button" className="btn-close m-0 shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group mb-4">
                                <textarea name="description" id="description" 
                                className="form-control shadow-none" 
                                rows="5" 
                                value={faqdescription}
                                onChange={(e) => setFaqDescription(e.target.value)}
                                ></textarea>

                                {faqDescriptionError && <p className="text-danger">Description is required</p>}

                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="attachFile" className="d-block fw-bold mb-2">{t('Attach File')} * {t('less than')}  20 MB</label>
                                <ul className="upload-files d-flex flex-wrap gap-2 text-center">

                                    <li className="upload">
                                        <label htmlFor="uploadFile" className="h-100 d-flex flex-column align-items-center justify-content-center p-3 position-relative cursor-pointer">
                                            <input 
                                                id="faqUploadFile" 
                                                type="file" 
                                                className="upload_fileBtn"
                                                multiple
                                                onChange={fileUploadHandler}
                                            />
                                            
                                            {isUploading == true ? 
                                                <div className="loading-spinner">Loading...</div>                                            
                                                :
                                                <>
                                                    <span className="d-block material-icons-outlined mb-2">upload</span>
                                                    <p className="name mb-1">{t('Upload')}</p>
                                                    <p className="size">doc/docs/ppt/pdf/xls/mp4</p>
                                                </>
                                            }

                                            
                                        </label>
                                    </li>

                                    {uploadedFiles.length > 0 && uploadedFiles.map((file, index) => {
                                        const fileExtension = file.name.split('.').pop().toLowerCase();
                                        const isVideo = ['mp4', 'webm', 'ogg'].includes(fileExtension);
                                        const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension);
                                        
                                        console.log('file', file);

                                        return (
                                            <li key={index} 
                                                className="file d-flex flex-column align-items-center justify-content-center p-3 position-relative">
                                                <Link to="#" 
                                                className="delete position-absolute top-0 end-0 mt-2 me-2"
                                                onClick={() => closeFileHandler(index)}
                                                >
                                                    <span className="d-block material-icons-outlined">delete</span>
                                                </Link>
                                                
                                                {isVideo ? (
                                                    <video controls className="img-fluid mb-2">
                                                        <source src={file.path} type={`video/${fileExtension}`} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : isImage ? (
                                                    <img src={file.path} className="img-fluid mb-2 image-thumb"/>
                                                ) : (
                                                    <img src={
                                                        fileExtension === 'pdf' ? brochurePdf :
                                                        ['doc', 'docx'].includes(fileExtension) ? brochureDoc :
                                                        ['xls', 'xlsx', 'csv'].includes(fileExtension) ? brochureXls :
                                                        ['ppt', 'pptx'].includes(fileExtension) ? brochurePpt :
                                                        brochureDoc // default fallback
                                                    } className="img-fluid mb-2"  />
                                                )}

                                                <p className="name mb-1">
                                                    {file.name.length > 20 
                                                        ? `${file.name.substring(0, 20)}...${fileExtension}`
                                                        : file.name}
                                                </p>
                                            </li>
                                        );
                                    })}

                                </ul>
                            </div>
                            <div className="action">
                                <button type="button" 
                                className="basic_btn ylw_btn w-auto"
                                onClick={handleSubmit}
                                >{t('Submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <SentModal />
        </>
    );
};

export default AskQuestion;