/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import brochurePdf from "assets/CourseDetailsAssests/images/PDF.jpg";
import brochureDoc from "assets/CourseDetailsAssests/images/DOC.jpg";
import brochurePpt from "assets/CourseDetailsAssests/images/PPT.jpg";
import brochureXls from "assets/CourseDetailsAssests/images/XLS.jpg";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";


const FaqDetails = () => {

    // user information
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const params = useParams();

    const [faqDetails, setFaqDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    /* ------------- Language translation imports starts here ------------- */
    const { t, i18n } = useTranslation();

    // get faq details
    const getFaqDetails = async (id) => {

        setIsLoading(true);

        try {
            const requestURL = url.API_BaseUrl + url.API_FAQ_DETAILS + `?token=${token}&id=${id}`;

            console.log('FAQ req url',requestURL);

            const response = await getData(requestURL);

            console.log('FAQ details response',response);

            if(response.status === true){
                setFaqDetails(response.data);
            }

            setIsLoading(false);

            
        } catch (error) {
            console.log(error.message);
        }
    }


    // useEffect from here
    useEffect(() => {
    getFaqDetails(params.id);
    }, [params.id]);

    return (
        <>
        {isLoading ? (
            <>
                <div className="placeholder-glow">
                    <h1 className="placeholder col-6"></h1>
                </div>

                <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                    <li className="fw-bold">{t('Tags')}</li>
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                    <li className="tag px-3 py-2 rounded-8 placeholder-glow">
                        <span className="placeholder col-3"></span>
                    </li>
                </ul>
                <div className="placeholder-glow">
                    <p className="placeholder col-12"></p>
                    <p className="placeholder col-12"></p>
                    <p className="placeholder col-12"></p>
                </div>
                <Link to="#" className="basic_btn ylw_btn w-auto mt-4 placeholder-glow">
                    <span className="placeholder col-6"></span>
                </Link>
                
                <div className="relate-to mt-5">
                    <h3 className="mb-3">{t('Related to')}:</h3>
                    
                    <h6 className="mb-2">{t('Post')}</h6>
                    <ul className="mb-3">
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>
                    <h6 className="mb-2">{t('Experts')}</h6>
                    <ul>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>

                    <h6 className="mb-2">{t('Courses')}</h6>
                    <ul>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                        <li className="placeholder-glow">
                            <Link to="#" className="d-flex align-items-center gap-2">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline placeholder col-8"></span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </>
        ) : (
        <>
            {/* Faq details */}
            <h1>{faqDetails.question}</h1>

            <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                <li className="fw-bold d-flex align-items-center gap-1">
                    {t('Tags')}
                    <span className="material-icons-outlined">sell</span>
                </li>
                {faqDetails.tags && faqDetails.tags.map((tag, index) => (
                    <li key={index} className="tag px-3 py-2 rounded-8">{tag.label}</li>
                ))}
            </ul>
            
            <p dangerouslySetInnerHTML={{ __html: faqDetails.answer }}></p>
            
            <h6 class="mb-3 mt-4">{t('Attach File')}</h6>
            <div className="d-flex gap-2 flex-wrap">
                {faqDetails.uploads && faqDetails.uploads.map((file, index) => {
                    const extension = file.name.split('.').pop().toLowerCase();

                    // Video files
                    if (['mp4', 'webm', 'ogg'].includes(extension)) {
                        return (
                            <div key={index} className="bg-gray position-relative">
                                <figure className="h-100 w-100 position-relative">
                                    <video controls className="w-100" style={{ maxWidth: '500px' }}>
                                        <source src={file.url} type={`video/${extension}`} />
                                        Your browser does not support the video tag.
                                    </video>
                                </figure>
                            </div>
                        );
                    }

                    // image files
                    if (['png', 'jpg', 'jpeg'].includes(extension)) {
                        return (
                            <div key={index} className="bg-gray position-relative" style={{ maxWidth: '500px' }}>
                                <figure className="h-100 w-100 position-relative">
                                    <img src={file.url} 
                                       style={{ maxWidth:'250px' }} 
                                       alt={file.name.length > 20 
                                            ? `${file.name.substring(0,15)}...${extension}`
                                            : file.name}
                                    />
                                </figure>
                            </div>
                        );
                    }

                    // Other file types
                    return (
                        <div key={index} className="bg-gray position-relative">
                            <span className="d-flex align-items-center justify-content-center file-icon p-3 border-bottom">
                                <img 
                                    src={
                                        extension === 'pdf' ? brochurePdf :
                                        extension === 'doc' || extension === 'docx' ? brochureDoc :
                                        extension === 'ppt' || extension === 'pptx' ? brochurePpt :
                                        extension === 'xls' || extension === 'xlsx' ? brochureXls :
                                        brochureDoc
                                    } 
                                    alt={extension} 
                                    style={{ width: '100px' }}
                                />
                            </span>
                            <div className="doc_info d-flex align-items-center gap-2 justify-content-between px-3 py-2">
                                <div className="left_info">
                                    <p className="text-dark-primary fs-sm fw-regular">
                                        {file.name.length > 20 
                                            ? `${file.name.substring(0,15)}...${extension}`
                                            : file.name}
                                    </p>
                                    
                                </div>
                                <a href={file.url} download className="d-inline-block text-gray fs-md">
                                    <span className="material-icons-outlined">file_download</span>
                                </a>
                            </div>
                        </div>

                    );


                })}                
            </div>
        
            
            {/* <Link to="#" className="basic_btn ylw_btn w-auto mt-4">{t('Any Doubt')}</Link> */}

            
            
            {/* related Faqs */}
            <div className="relate-to mt-5">
                <h3 className="mb-3">{t('Related to')}:</h3>
                <h6 className="mb-2">{faqDetails.experts && faqDetails.experts.length > 0 ? t('Champions') : ''}</h6>
                <ul>
                    {faqDetails.experts && faqDetails.experts.map((expert, index) => (
                        <li key={index}>
                            <Link to={`/champions/details/${expert.id}`} className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{expert.label} - {expert.email}</span>
                            </Link>
                        </li>
                    ))}
                    
                </ul>


                
                <h6 className="mt-2 mb-2">{faqDetails.posts && faqDetails.posts.length > 0 ? t('Post') : ''}</h6>
                <ul className="mb-3">
                    {faqDetails.posts && faqDetails.posts.map((post, index) => (
                        <li key={index}>
                            <Link to={`/blog/${post.slug}`} className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{post.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                <h6 className="mt-2 mb-2">{faqDetails.courses && faqDetails.courses.length > 0 ? t('Course') : ''}</h6>
                <ul className="mb-3">
                    {faqDetails.courses && faqDetails.courses.map((course, index) => (
                        <li key={index}>
                            <Link to="#" className="d-flex align-items-center gap-2" target="_blank">
                                <span className="d-block material-icons-outlined">link</span>
                                <span className="d-block text-decoration-underline">{course.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>

                          
            </div>
        
        </>
        )}
        </>
    );
};

export default FaqDetails;